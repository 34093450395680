import { theme } from 'styles';

export const shoppingCartPopupContainer = (isShow) => ({
  width: '100%',
  minHeight: '4.8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 0,
  backgroundColor: theme.white,

  '.shopping-cart-badge-container': {
    position: 'relative',

    '.icon-nav-actions': {
      padding: 4,
      borderRadius: 10,
      backgroundColor: isShow ? theme.secondaryDarkLight : 'none',
    },

    '@media (max-width: 576px)': {
      padding: '0.25em',
    },
  },

  '.opened-content-container': {
    width: '44rem',
    height: 500,
    position: 'absolute',
    top: 60,
    right: 20,
    padding: '1em 2em',
    backgroundColor: theme.white,
    color: theme.gray500,
    border: `1px solid ${theme.secondary}`,
    borderRadius: '0.25rem',
    boxShadow: theme.boxShadow,

    '.product-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',

      '::-webkit-scrollbar': {
        display: 'none',
      },

      '.spinner-container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '.mini-cart-items-container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '.header-title': {
          marginBottom: '1em',
        },

        '.cart-items-container': {
          maxHeight: '36rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          overflowY: 'auto',
          overflowX: 'hidden',
          marginBottom: '1em',
          padding: '0.6em 0',

          '& > div': {
            marginBottom: '1.3em',

            '&:last-of-type': {
              marginBottom: 0,
            },
          },
        },

        '.proceed-button': {
          textTransform: 'uppercase',
        },
      },
    },

    '@media (max-width: 576px)': {
      width: '100%',
      right: 0,
      top: 48,
    },
  },
});

export const badgeNumber = (showNumber) => ({
  position: 'absolute',
  top: 0,
  right: '0.3em',
  display: showNumber && 'none',
  padding: '0.3em 0.3em',
  color: theme.gray100,
  backgroundColor: theme.warning,
  fontSize: '0.8rem',
  fontWeight: 700,
  borderRadius: 2,
});
