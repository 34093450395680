import { createStore, showApiError, useStore } from 'components';
import { ALL_PRODUCTS_MOCK_DATA } from 'enums';
import { getProductDetails } from 'services';

const getProductData = (productId) => ALL_PRODUCTS_MOCK_DATA.find((el) => el.itemId === productId);

const initProductsState = {
  cleaningGel: { ...getProductData(1) },
  fillerSerum: { ...getProductData(2) },
  eyeCream: { ...getProductData(3) },
  dayCream: { ...getProductData(4) },
  nightCream: { ...getProductData(5) },
  calmingAndHydratingMask: { ...getProductData(6) },
  metabolicMask: { ...getProductData(7) },
};

const productsStore = createStore(initProductsState);

export const initProductsStore = async () => {
  const [resCleaningGel, errCleaningGel] = await getProductDetails(initProductsState.cleaningGel.dealName);
  errCleaningGel?.data?.errors?.[0]?.code && showApiError(errCleaningGel.data?.errors?.[0]?.code);

  const [resFillerSerum, errFillerSerum] = await getProductDetails(initProductsState.fillerSerum.dealName);
  errFillerSerum?.data?.errors?.[0]?.code && showApiError(errFillerSerum.data?.errors?.[0]?.code);

  const [resEyeCream, errEyeCream] = await getProductDetails(initProductsState.eyeCream.dealName);
  errEyeCream?.data?.errors?.[0]?.code && showApiError(errEyeCream.data?.errors?.[0]?.code);

  const [resDayCream, errDayCream] = await getProductDetails(initProductsState.dayCream.dealName);
  errDayCream?.data?.errors?.[0]?.code && showApiError(errDayCream.data?.errors?.[0]?.code);

  const [resNightCream, errNightCream] = await getProductDetails(initProductsState.nightCream.dealName);
  errNightCream?.data?.errors?.[0]?.code && showApiError(errNightCream.data?.errors?.[0]?.code);

  const [resCalmingAndHydratingMask, errCalmingAndHydratingMask] = await getProductDetails(
    initProductsState.calmingAndHydratingMask.dealName,
  );
  errCalmingAndHydratingMask?.data?.errors?.[0]?.code &&
    showApiError(errCalmingAndHydratingMask.data?.errors?.[0]?.code);

  const [resMetabolicMask, errMetabolicMask] = await getProductDetails(initProductsState.metabolicMask.dealName);
  errMetabolicMask?.data?.errors?.[0]?.code && showApiError(errMetabolicMask.data?.errors?.[0]?.code);

  productsStore.setState((prev) => [
    { ...prev.cleaningGel, ...resCleaningGel },
    { ...prev.fillerSerum, ...resFillerSerum },
    { ...prev.eyeCream, ...resEyeCream },
    { ...prev.dayCream, ...resDayCream },
    { ...prev.nightCream, ...resNightCream },
    { ...prev.calmingAndHydratingMask, ...resCalmingAndHydratingMask },
    { ...prev.metabolicMask, ...resMetabolicMask },
  ]);
};

export const useProductsStore = (...args) => useStore(productsStore, ...args);
