import { useTranslations } from '@veraio/strank';
import { forgotPassword } from '@oneecosystem/authenticate';
import { Form, Input, inputValidation, Icon } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { actionContainer, forgotPasswordContainer } from './styles';

const ForgotPassword = ({ history }) => {
  const { getText } = useTranslations();

  return (
    <div css={forgotPasswordContainer}>
      <Logo onClick={() => history.push(ROUTES.Home)} className="logo" />

      <Form className="form-container">
        <div className="lock-icon-container">
          <Icon iconName="lock" size={26} />
        </div>
        <h3 className="title">{getText('troubleWithLoggingIn')}</h3>
        <p className="desc">{getText('enterEmailToGetBackIntoYourProfile')}</p>
        <Input
          required
          formId="email"
          margin="0 0 1em"
          label={getText('emailAddress')}
          validate={inputValidation('required', 'email')}
        />
        {({ values }) => {
          const isValidEmail =
            // eslint-disable-next-line max-len
            /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              values.email,
            );

          return (
            <div css={actionContainer(isValidEmail)}>
              <a href={isValidEmail && forgotPassword()} className="login-button">
                {getText('confirm')}
              </a>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default ForgotPassword;
