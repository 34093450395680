import { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import {
  cryptoSign,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // useLocations, changeUserCurrency
} from '@oneecosystem/dealshaker-core';
import {
  Button,
  Col,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Dropdown,
  Image,
  Modal,
  Row,
} from 'components';
import { FOOTER_ALL_PRODUCTS_MOCK_DATA, ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { allFromVita, onlineVita } from './config';
import { footerContainer, modalContainer } from './styles';

const Footer = (props) => {
  const { isMobile, className } = props;
  const {
    getText,
    // REMOVE TAG HIDE FOR FIRST VERSION
    //  changeLanguage
  } = useTranslations();
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const geoLocation = useLocations();
  const getYear = new Date().getFullYear();
  const history = useHistory();
  const privacyPolicyModalRef = useRef();
  const termsAndConditionsModalRef = useRef();

  // TO-DO make select country to work for every cases currency, language and prices
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const handleChangeCountry = (val) => {
  //   changeUserCurrency(val);
  //   changeLanguage(val);
  // };

  return (
    <>
      <div css={footerContainer(isMobile)} {...(className && { className })}>
        <div className="products-footer-container">
          <Row>
            <Col xl={4} lg={4} md={6} sm={6} xs={6}>
              <p className="footer-label">{getText('products')}</p>
              <Row horizontalGap={32}>
                {FOOTER_ALL_PRODUCTS_MOCK_DATA?.map((el, ind) => (
                  <Col xl={6} lg={6} md={6} sm={6} xs={6} key={ind}>
                    <Button
                      type="link"
                      onClick={() => history.push({ pathname: `/products/${el.itemId}`, state: el })}
                      className="footer-link-button">
                      {getText(el.footerTitle)}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xl={2} lg={3} md={6} sm={2} xs={12} />
            <Col xl={3} lg={5} md={6} sm={6} xs={6}>
              <p className="footer-label">{getText('fromOneVita')}</p>
              <Row>
                {allFromVita?.map((el, ind) => (
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} key={ind}>
                    <Button type="link" linkTo={el.route} className="footer-link-button">
                      {getText(el.title)}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xl={3} lg={12} md={6} sm={6} xs={6}>
              <p className="footer-label">{getText('keepInTouch')}</p>
              <a href="mailto: onevita@oneecosystem.eu" className="touch-text">
                onevita@oneecosystem.eu
              </a>
            </Col>
          </Row>

          <div className="online-container">
            <p className="footer-label">{getText('oneVitaOnline')}</p>
            <p className="online-description">{getText('findUsOnlineAndFollowUs')}</p>
            <div className="social-container">
              {onlineVita?.map((el, ind) => (
                <div key={ind} role="button" tabIndex={0} onClick={() => window.open(el.route, '_blank')}>
                  <Image src={el.icon} alt="icon" width={55} height={55} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="footer-container">
          <div className="logo-country-container">
            <Link to={ROUTES.Home} className="logo-container">
              <Logo className="logo" />
            </Link>
            {/* REMOVE TAG HIDE FOR FIRST VERSION
          <Dropdown
            small
            noClear
            value={geoLocation?.geoLocation?.country}
            options={geoLocation?.countries}
            displayKey="name"
            className="dropdown"
            onChange={handleChangeCountry}
            mapValue={(val) => val?.name}
          /> */}
          </div>

          <div className="terms-rights-container">
            <p className="text">
              &nbsp; © {getYear}, <span>{getText('oneVitaAllRightsReserved')}</span>
            </p>
            <Button type="link" onClick={() => termsAndConditionsModalRef.current?.open()} className="button">
              {getText('termsAndConditions')}
            </Button>
            <Button type="link" onClick={() => privacyPolicyModalRef.current?.open()} className="button">
              {getText('privacyPolicy')}
            </Button>
            {/* REMOVE TAG HIDE FOR FIRST VERSION
            <Button type="link" onClick={() => history.push(ROUTES.HelpPage)} className="button">
              {getText('delivery')}
            </Button> */}
          </div>
        </div>
      </div>
      <Modal large ref={privacyPolicyModalRef} closeIcon css={modalContainer}>
        <h3 className="title">{getText('privacyPolicy')}</h3>
        <hr className="horizontal-line" />
        <div className="scrollable-container">
          <p className="more-info">{getText('privacyPolicyDescription')}</p>
        </div>
      </Modal>

      <Modal large ref={termsAndConditionsModalRef} closeIcon css={modalContainer}>
        <h3 className="title">{getText('termsAndConditions')}</h3>
        <hr className="horizontal-line" />
        <div className="scrollable-container">
          <p className="more-info">{getText('termsAndConditionsDescription', { isCrypto: cryptoSign() })}</p>
        </div>
      </Modal>
    </>
  );
};

Footer.propTypes = {
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export default Footer;
