import { theme } from 'styles';

export const priceContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '.price-styles': {
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.5,
    whiteSpace: 'break-spaces',

    '.quantity': {
      color: theme.gray300,
      letterSpacing: 2,
    },
  },

  '.before-discount-styles': {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: theme.gray200,
    textDecoration: 'line-through',
    margin: '0 0.25em 0 0',
    lineHeight: 1.5,
  },

  '.saving-styles': {
    fontSize: '1rem',
    color: theme.gray200,
    marginTop: '0.4em',
  },
};
