import { theme } from 'styles';

export const userContainer = {
  width: '100%',

  '.profile-nav-styles': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    padding: 8,
    width: '100%',

    '.avatar-image': {
      width: 26,
      height: 26,
      marginRight: 8,
    },
  },

  '.opened-container': {
    position: 'absolute',
    top: 50,
    right: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    backgroundColor: theme.white,
    borderRadius: 6,
    color: theme.black40,
    width: '15em',
    boxShadow: theme.boxShadow,

    '& > *': {
      marginBottom: 12,
    },

    '& .button': {
      width: '100%',
    },
  },
};

export const profile = {
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `0.5px solid ${theme.gray100}`,
  width: '100%',
  color: theme.gray200,

  '.item': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 16px',

    '&:hover': {
      backgroundColor: theme.gray100,
    },

    '& > h5': {
      color: theme.gray400,
      marginLeft: 12,
    },
  },
};
