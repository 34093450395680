import { ROUTES } from 'enums';

export const allFromVita = [
  {
    id: 1,
    title: 'skinCare',
    route: ROUTES.SkinCare,
  },
  {
    id: 2,
    title: 'brandStory',
    route: ROUTES.BrandStory,
  },
  // REMOVE TAG HIDE FOR FIRST VERSION
  // {
  //   id: 3,
  //   title: 'becomeAnAffiliate',
  //   route: ROUTES.BecomeAnAffiliate,
  // },
];

export const onlineVita = [
  {
    id: 1,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/facebook-icon.svg',
    route: 'https://www.facebook.com/profile.php?id=61567664738846',
  },
  // REMOVE TAG HIDE FOR FIRST VERSION
  // {
  //   id: 2,
  //   icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/twitter-icon.svg',
  //   route: 'https://x.com',
  // },
  {
    id: 3,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/instagram-icon.svg',
    route: 'https://www.instagram.com/onevita.skincare',
  },
];
