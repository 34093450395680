import { getReq, postReq, deleteReq, putReq } from 'services/axios/makeRequest';
import { updateShoppingCartDeliveryBody, updateShoppingCartProductQuantityBody, addToShoppingCartBody } from 'services';

const baseUrl = `${apiUrls.dealshakerPortalApiUrl}/api/ShoppingCart`;

export const getShoppingCart = () => getReq(`${baseUrl}/Get`);

export const addToShoppingCart = (data) => postReq(`${baseUrl}/Add`, addToShoppingCartBody(data));

export const updateShoppingCartProductQuantity = (data) =>
  putReq(`${baseUrl}/Quantity`, updateShoppingCartProductQuantityBody(data));

export const updateShoppingCartDelivery = (items) =>
  putReq(`${baseUrl}/Delivery`, updateShoppingCartDeliveryBody(items));

export const updateShoppingCartPaymentMethods = (data) => putReq(`${baseUrl}/PaymentMethod`, data);

export const removeProductFromShoppingCart = (dealId) => deleteReq(`${baseUrl}/Delete/${dealId}`);

export const removeAllProductsFromShoppingCart = () => deleteReq(`${baseUrl}/Delete`);
