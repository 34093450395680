import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Image } from 'components';
import { useUserStore } from 'stores';
import { emptyShoppingCartContainer } from './styles';

const EmptyShoppingCart = ({ onClickLogin }) => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore();

  return (
    <div css={emptyShoppingCartContainer}>
      <Image
        src="https://oneecosystem.blob.core.windows.net/vita/apps/images/cart.png"
        alt="empty-shopping-cart"
        iconName="shopping_cart"
        className="empty-cart-image"
      />
      <h4 className="title">{getText('yourCartIsEmpty')}</h4>
      {!isAuthenticated && (
        <Button type="primary" small onClick={onClickLogin} className="try-to-login-button">
          {getText('tryLoggingIn')}
        </Button>
      )}
    </div>
  );
};

EmptyShoppingCart.propTypes = {
  onClickLogin: PropTypes.func,
};

export default EmptyShoppingCart;
