import { INGREDIENTS } from './ingredients';

const getIngredientsById = (array) => INGREDIENTS.filter((el) => array.find((elem) => elem === el.id));

export const ALL_PRODUCTS_MOCK_DATA = [
  {
    itemId: 1,
    dealName: productNames.cleaningGel,
    miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-cleaning-gel.svg',
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-cleansing-gel.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cleansing-gel-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-cleaning-gel.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-cleansing-gel.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-cleansing-gel.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-cleancing-gel2.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-cleansing-gel.jpg',
    isRightInfo: true,
    isRightImage: false,
    isMask: false,
    title: 'liposomalCleansingGel',
    footerTitle: 'cleansingGel',
    navTitle: 'cleansingGel',
    description: 'liposomalCleansingGelDescription',
    keyIngredients: 'thisCleanserContainsOnlyNaturalActiveCleansingIngredients',
    productDetailsDescription: 'ourRevolutionaryLiposomalCleansingGel',
    productIngredients: [
      'complexCleansing',
      'lipidReplacement',
      'gentleAndMoisturizing',
      'barrierBalancing',
      'mineralEnriched',
    ],
    total: 89,
    priceFiat: 44.5,
    priceCrypto: 1.0471,
    quantityMl: 100,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Cleansing-gel.pdf',
    dealshakerProductUrl: productUrls.cleaningGel,
    ingredients: getIngredientsById([1, 2, 3, 4, 5, 6]),
  },
  {
    itemId: 2,
    dealName: productNames.fillerSerum,
    miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-filler-serum.svg',
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-filler-serum.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/filler-serum-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-filler-serum.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-filler-serum.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-filler-serum.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-filler-serum2.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-filler-serum.jpg',
    isRightInfo: false,
    isRightImage: true,
    isMask: false,
    title: 'liposomalFillerSerum',
    footerTitle: 'fillerSerum',
    navTitle: 'fillerSerum',
    description: 'liposomalFillerSerumDescription',
    keyIngredients: 'packedWithDifferentTypesOfCollagen',
    productDetailsDescription: 'ourLiposomalPlumpingSerumInnovativeSkincareProduct',
    productIngredients: ['plumpingEffect', 'immediateTightening', 'maskingWrinkles', 'lifting', 'hydrating'],
    total: 119,
    priceFiat: 59.5,
    priceCrypto: 1.4,
    quantityMl: 40,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Filler-serum.pdf',
    dealshakerProductUrl: productUrls.fillerSerum,
    ingredients: getIngredientsById([7, 8, 9, 10, 11, 12, 13, 14, 15, 16]),
  },
  {
    itemId: 3,
    dealName: productNames.eyeCream,
    miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-eye-cream.svg',
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-eye-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/eye-cream-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-eye-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-eye-cream.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-eye-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-eye-cream2.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-eye-cream.jpg',
    isRightInfo: true,
    isRightImage: false,
    isMask: false,
    title: 'liposomalEyeCream',
    footerTitle: 'eyeCream',
    navTitle: 'eyeContouringCream',
    description: 'liposomalEyeCreamDescription',
    keyIngredients: 'packedWithLiposomeLoadedQtenAndCaffeine',
    productDetailsDescription: 'yourEyesDeserveOnlyBestOurExtraordinaryLiposomal',
    productIngredients: ['brightening', 'immediateTightening', 'plumpingAndLifting', 'antiDarkCircle', 'hydrating'],
    total: 89,
    priceFiat: 44.5,
    priceCrypto: 1.0471,
    quantityMl: 20,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Eye-Contouring.pdf',
    dealshakerProductUrl: productUrls.eyeCream,
    ingredients: getIngredientsById([17, 18, 19, 20, 21, 22, 23, 24, 25, 26]),
  },
  {
    itemId: 4,
    dealName: productNames.dayCream,
    miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-day-cream.svg',
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-day-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/day-cream-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-day-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-day-cream.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-day-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-day-cream2.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-day-cream.jpg',
    isRightInfo: true,
    isRightImage: true,
    isMask: false,
    title: 'liposomalDayCream',
    footerTitle: 'dayCream',
    navTitle: 'dayCreamAndMoisturizer',
    description: 'liposomalDayCreamDescription',
    keyIngredients: 'thisBioactiveDayCreamMoisturizerOffersLiposomes',
    productDetailsDescription: 'revolutionaryLiposomalMoisturizingDayCreamWithZeolites',
    productIngredients: [
      'reHydration',
      'collagenBoost',
      'barrierBalancing',
      'spfProtection',
      'moisturizing',
      'soothing',
    ],
    total: 99,
    priceFiat: 49.5,
    priceCrypto: 1.1647,
    quantityMl: 50,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Day-cream.pdf',
    dealshakerProductUrl: productUrls.dayCream,
    ingredients: getIngredientsById([27, 28, 29, 30, 31, 32, 33, 34, 35]),
  },
  {
    itemId: 5,
    dealName: productNames.nightCream,
    miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-night-cream.svg',
    listingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-night-cream.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/night-cream-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-night-cream.jpg',
    introducingImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-night-cream.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-night-cream.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-night-cream2.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-night-cream.jpg',
    isRightInfo: false,
    isRightImage: false,
    isMask: false,
    title: 'liposomalNightCream',
    footerTitle: 'nightCream',
    navTitle: 'nightCream',
    description: 'liposomalNightCreamDescription',
    keyIngredients: 'mostPowerfulNightComboLiposomalRetinol',
    productDetailsDescription: 'investInYourSkincareRoutineWithOurExclusiveNightCream',
    productIngredients: ['barrierBalancing', 'antiOx', 'skinBuilding', 'antiWrinkle'],
    total: 99,
    priceFiat: 49.5,
    priceCrypto: 1.1647,
    quantityMl: 50,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Night-cream.pdf',
    dealshakerProductUrl: productUrls.nightCream,
    ingredients: getIngredientsById([36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47]),
  },
  {
    itemId: 6,
    dealName: productNames.calmingAndHydratingMask,
    miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-calming-hydrating-mask.svg',
    listingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-calming-hydrating-mask.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/calming-hydrating-mask-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-calming-hydrating-mask.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-calming-hydrating-mask.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-calming-hydrating-mask.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-masks.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-calming-hydrating-mask.jpg',
    isRightInfo: true,
    isRightImage: true,
    isMask: true,
    title: 'liposomalCalmingAndHydratingMask',
    footerTitle: 'calmingAndHydratingMask',
    navTitle: 'calmingAndHydratingMask',
    description: 'liposomalCalmingAndHydratingMaskDescription',
    keyIngredients: 'pcaMineralsSaltsTrehaloseLiposomalQtenMushroom',
    productDetailsDescription: 'deepMoisturizingPropertiesOfThisMaskWorkFast',
    productIngredients: [
      'restoresSkinEssentialMoisture',
      'continualBurstOfHydration',
      'softensAndSmooths',
      'boostsRadiance',
      'infusedWithAntioxidants',
    ],
    total: 59,
    priceFiat: 29.5,
    priceCrypto: 0.6941,
    quantityMl: 4,
    numberOfQuantity: 10,
    downloadPdfLink:
      'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Calming-and-hydrating-mask.pdf',
    dealshakerProductUrl: productUrls.calmingAndHydratingMask,
    ingredients: getIngredientsById([48, 49, 50, 51, 52, 53, 54, 55, 56]),
  },
  {
    itemId: 7,
    dealName: productNames.metabolicMask,
    miniCartImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/mini-cart-metabolic-activating-mask.svg',
    listingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/listing-product-metabolic-activating-mask.jpg',
    sliderImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/metabolic-activating-mask-carousel.jpg',
    imageNav: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/navigation-metabolic-activating-mask.jpg',
    introducingImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/product-introducing-metabolic-activating-mask.gif',
    keyIngredientsImage:
      'https://oneecosystem.blob.core.windows.net/vita/apps/images/key-ingredients-product-metabolic-activating-mask.jpg',
    allProductsImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-masks.jpg',
    coverImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cover-metabolic-activating-mask.jpg',
    isRightInfo: false,
    isRightImage: false,
    isMask: true,
    title: 'liposomalMetabolicActivatingMask',
    footerTitle: 'metabolicActivatingMask',
    navTitle: 'metabolicActivatingMask',
    description: 'liposomalMetabolicActivatingMaskDescription',
    keyIngredients: 'lactobacillusFermentLysateFiltrateChilliPeptide',
    productDetailsDescription: 'powerfulActivatingMaskWorksToIncreaseAccelerate',
    productIngredients: [
      'acceleratesSubstanceExchange',
      'cellularEnergizing',
      'rejuvenates',
      'antiAging',
      'improvingAndAcceleratingSkinMetabolics',
    ],
    total: 59,
    priceFiat: 29.5,
    priceCrypto: 0.6941,
    quantityMl: 4,
    numberOfQuantity: 10,
    downloadPdfLink: 'https://oneecosystem.blob.core.windows.net/vita/apps/documents/One-Vita-Metabolic-mask.pdf',
    dealshakerProductUrl: productUrls.metabolicMask,
    ingredients: getIngredientsById([57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67]),
  },
];

export const PRODUCTS_SET_DATA = {
  dealName: 'liposomal-5-product-set-CD3752AB58',
  miniCartImage: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/mobileThumbnail-2160x3480.jpg',
  title: 'Liposomal 5-product set',
  total: 349,
  priceFiat: 174.5,
  priceCrypto: 4.1059,
};

export const ROUTINE_PRODUCTS_DATA = [
  {
    itemId: 1,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-cleaning-gel.svg',
    title: 'purifyAndRefresh',
    description: 'effortlesslyLiftAwayImpuritiesAndMakeup',
    buttonText: 'liposomalCleansingGel',
    isRightImage: true,
  },
  {
    itemId: 2,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-filler-serum.svg',
    title: 'elasticityAndSmoothness',
    description: 'powerfulElasticityBoostingIngredients',
    buttonText: 'liposomalFillerSerum',
    isRightImage: false,
  },
  {
    itemId: 3,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-eye-cream.svg',
    title: 'eyeRevival',
    description: 'targetedHydrationThatAddressesPuffiness',
    buttonText: 'liposomalEyeCream',
    isRightImage: true,
  },
  {
    itemId: 4,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-day-cream.svg',
    title: 'daytimeDefense',
    description: 'hydrateAndShieldAgainstDailyStressors',
    buttonText: 'liposomalDayCream',
    isRightImage: false,
  },
  {
    itemId: 5,
    image: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-night-cream.svg',
    title: 'overnightRenewal',
    description: 'enhanceYourSkinNaturalRenewalProcessOvernight',
    buttonText: 'liposomalNightCream',
    isRightImage: true,
  },
];

export const FOOTER_ALL_PRODUCTS_MOCK_DATA = [
  ALL_PRODUCTS_MOCK_DATA[0],
  ALL_PRODUCTS_MOCK_DATA[4],
  ALL_PRODUCTS_MOCK_DATA[1],
  ALL_PRODUCTS_MOCK_DATA[5],
  ALL_PRODUCTS_MOCK_DATA[2],
  ALL_PRODUCTS_MOCK_DATA[6],
  ALL_PRODUCTS_MOCK_DATA[3],
];
