import { SAMPLE_INGREDIENTS } from './sampleIngredients';

const getIngredientsById = (array) => SAMPLE_INGREDIENTS.filter((el) => array.find((elem) => elem === el.id));

export const INGREDIENTS = [
  {
    id: 1,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitamins-icon.png',
    title: 'vitamins',
    description: 'vitaminsDescription',
    ingredientsList: getIngredientsById([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  },
  {
    id: 2,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/minerals-rca-icon.png',
    title: 'mineralsPca',
    description: 'mineralsPcaDescription',
    ingredientsList: getIngredientsById([13, 14, 15, 16]),
  },
  {
    id: 3,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/olive-leaf-extract-licorice-extract-icon.png',
    title: 'oliveLeafExtractAndLicoriceExtract',
    description: 'oliveLeafExtractAndLicoriceExtractDescription',
    ingredientsList: getIngredientsById([29, 30]),
  },
  {
    id: 4,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/b5-aloe-vera-icon.png',
    title: 'panthenolAndAloeVera',
    description: 'panthenolAndAloeVeraDescription',
    ingredientsList: getIngredientsById([27, 28]),
  },
  {
    id: 5,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/fruit-enzymes-icon.png',
    title: 'fruitEnzymes',
    description: 'fruitEnzymesDescription',
    ingredientsList: getIngredientsById([17, 18, 19, 20, 21, 22, 23, 24, 25, 26]),
  },
  {
    id: 6,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/glycolic-acid-icon.png',
    title: 'glycolicAcid',
    description: 'glycolicAcidDescription',
  },
  {
    id: 7,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/peptides.png',
    title: 'peptidesAcetylHexapeptidePalmitoylTripeptide',
    description: 'neuroSignallersSignalToSpecificCellsAndOrganisms',
    subDescription: 'peptidicFormPeptidesEasierAbsorbAndGetSkinMatrix',
    ingredientsList: getIngredientsById([31, 32, 33, 34, 35]),
  },
  {
    id: 8,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
    title: 'liposomeCaffeine',
    description: 'optimizingCapillaryCirculationMakesSkinLookBrighter',
  },
  {
    id: 9,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-gums.png',
    title: 'naturalGumsAcaciaGumRhizobianGumPvp',
    description: 'fillsExistingWrinkleCavityStretchesSkin',
    ingredientsList: getIngredientsById([36, 37, 38]),
  },
  {
    id: 10,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sea-weeds.png',
    title: 'seaWeedsKelpSpirulina',
    description: 'naturalTightenersWithAbundanceOfMineralsAndVitamins',
    ingredientsList: getIngredientsById([39, 40]),
  },
  {
    id: 11,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/collagens.png',
    title: 'collagens11Types',
    description: 'essentialProteinForBeautifulAndTightSkin',
  },
  {
    id: 12,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/elderberry-fruit.png',
    title: 'elderberryFruit',
    description: 'powerfulAntioxidantWithDirectImpactToReducingMicroInflammation',
  },
  {
    id: 13,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
    title: 'allantoin',
    description: 'naturallyOccurringManyPlantsAbilityToStimulateNewSkinGrowth',
  },
  {
    id: 14,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
    title: 'niacinamide',
    description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
  },
  {
    id: 15,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/procollagen.png',
    title: 'procollagen',
    description: 'collagenPrecursorProvideBetterCollagenSynthesis',
  },
  {
    id: 16,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/triple-size-hyaluronoc-acid.png',
    title: 'tripleSizeHyaluronicAcid',
    description: 'hydratingThreeSkinLayers',
  },
  {
    id: 17,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
    title: 'vitaminCCocktailAscorbicAcidRosehipCo2ExtractSodiumAscorbylSulphate',
    description: 'topicalVitaminCScienceBackedDermatologistsAllTimeFavorite',
    ingredientsList: getIngredientsById([41, 42, 43, 44]),
  },
  {
    id: 18,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/peptides.png',
    title: 'peptidesAcetylHexapeptide8AargirelinePalmitoylTripeptide1',
    description: 'chosenPreciselyForSignalingAntiWrinkleTighteningAndBrighteningEffects',
    subDescription: 'transformSkinContoursLongTermAndHelpSkin',
    ingredientsList: getIngredientsById([45, 46, 47, 48, 49, 50, 51, 52]),
  },
  {
    id: 19,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/beta-carotene.png',
    title: 'carrotCo2ExtractBetaCarotene',
    description: 'richestSourceOfNaturalVitaminA',
  },
  {
    id: 20,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/antioxidants.png',
    title: 'antioxidantsCurcumaCo2LicoriceGreenTea',
    description: 'powerfulBotanicalAntiOxToHelpBrightenDarkCircles',
    ingredientsList: getIngredientsById([53, 54, 55]),
  },
  {
    id: 21,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/triple-size-hyaluronoc-acid.png',
    title: 'tripleSizeHyaluronicAcid',
    description: 'glueHoldsCollagenSkinTogether',
  },
  {
    id: 22,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
    title: 'liposomeCaffeine',
    description: 'byOptimizingCapillaryCirculationSkinMakesSkinLookBrighter',
  },
  {
    id: 23,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
    title: 'liposomeQTen',
    description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
  },
  {
    id: 24,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/calcium.png',
    title: 'calcium',
    description: 'formulatedRightWayToBeAbsorbedBySkin',
  },
  {
    id: 25,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/botanical-oils.png',
    title: 'botanicalOilsAbbysinianRedGrapeSeedSqualaneApricot',
    description: 'plumpersAndNutrientsWithBotanicalBackground',
    ingredientsList: getIngredientsById([56, 57, 58, 59]),
  },
  {
    id: 26,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
    title: 'niacinamide',
    description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
  },
  {
    id: 27,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
    title: 'liposomeQTen',
    description: 'energizesAndAffectsBuildUpOfCollagenToReduceWrinkles',
  },
  {
    id: 28,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
    title: 'probiotic',
    description: 'lactobacillusCultureImprovesBarrierFunction',
  },
  {
    id: 29,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitamins-icon.png',
    title: 'vitaminPowerhouse',
    description: 'vitaminPowerhouseBiotinPanthenolThiamineHciCyanocobalamin',
    subDescription: 'energizeSpeedsUpSkinMetabolismAndCreateYoungerFunctioningSkin',
    ingredientsList: getIngredientsById([60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71]),
  },
  {
    id: 30,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/vitaminC-icon.png',
    title: 'liposomeVitaminC',
    description: 'increasedStabilityOfVitaminCEnsuresAmazingAntioxidant',
  },
  {
    id: 31,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
    title: 'liposomeCinnamon',
    description: 'powerAntioxToBalanceSkinTone',
  },
  {
    id: 32,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
    title: 'fructoseGlucoseSucrose',
    description: 'powerfulHumectantsToKeepMoistureLockedSkin',
    ingredientsList: getIngredientsById([72, 73, 74]),
  },
  {
    id: 33,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/alanine.png',
    title: 'ureaAlanineAsparticAcidHexylNicotinate',
    description: 'humectantsMoisturizers',
    ingredientsList: getIngredientsById([75, 76, 77, 78]),
  },
  {
    id: 34,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/caffein-icon.png',
    title: 'liposomeCaffeine',
    description: 'increasesPhotoProtectionAndBoostsSkinMetabolism',
  },
  {
    id: 35,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/nmf-natural-moisturizing-factors.png',
    title: 'nmfNaturalMoisturizingFactors',
  },
  {
    id: 36,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/amino-acids.png',
    title: 'aminoAcidsGlutamineLeucinePhenylalanineArginineLysineHclProline',
    subDescription: 'importantNutrientsRequiredForWoundHealingPromotion',
    ingredientsList: getIngredientsById([
      79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
    ]),
  },
  {
    id: 37,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
    title: 'liposomeQTen',
    description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
  },
  {
    id: 38,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/omega-3-6.png',
    title: 'omega36EssentialFattyAcidsLinoleicAndLinolenicAcid',
    description: 'healthySkinNeedNoSpecialExplanation',
    subDescription: 'helpPreventWaterLossAndStrengthenSkin',
    ingredientsList: getIngredientsById([99, 100]),
  },
  {
    id: 39,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/ceramides.png',
    title: 'ceramidesCeramide1Ceramide2Ceramide3Ceramide4Ceramide6Cholesterol',
    description: 'skinBuildersWithVerySimplePhilosophy',
    subDescription: 'buildingUpStrongSkinMatrix',
    ingredientsList: getIngredientsById([103, 104, 105, 106, 107, 108]),
  },
  {
    id: 40,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/retinol-icon.png',
    title: 'liposomeRetinol',
    description: 'twoSourcesLiposomeAndMicroencapsulatedBothEnsureRetinol',
    subDescription: 'oneOfRareIngredientsDermatologyConsiders',
    ingredientsList: getIngredientsById([101, 102]),
  },
  {
    id: 41,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/liposome-melatonin.png',
    title: 'liposomeMelatonin',
    description: 'naturalRestorativeAntiOxPowerhouseToRechargeSkinRadiance',
  },
  {
    id: 42,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cold-pressed-oils.png',
    title: 'coldPressedOilsWalnutApricotRedGrapeSeed',
    description: 'replaceLipidsAndAddPlumpnessToAgingSkin',
    ingredientsList: getIngredientsById([109, 110, 111]),
  },
  {
    id: 43,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/sea-weeds.png',
    title: 'seaWeedsOarweedAndSingleCellGreenAlgae',
    description: 'promoteYouthfulSkinByAbundanceOfUsefulMinerals',
    subDescription: 'singleCellAlgaePromoteStrongCellularRenewal',
    ingredientsList: getIngredientsById([115, 116]),
  },
  {
    id: 44,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/bakuchiol.png',
    title: 'bakuchiolSachaInchiOilSqualane',
    description: 'richVitaminAAndOmega',
    ingredientsList: getIngredientsById([112, 113, 114]),
  },
  {
    id: 45,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/rice-lipides.png',
    title: 'riceLipids',
    description: 'strongMoisturizerAndBarrierEnhancer',
  },
  {
    id: 46,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
    title: 'allantoin',
    description: 'naturallyOccurringManyPlantsAbilityToStimulateNewSkinGrowth',
  },
  {
    id: 47,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
    title: 'niacinamide',
    description: 'improveSkinHydrationByPreventingEvaporationOfMoistureFromSkin',
  },
  {
    id: 48,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
    title: 'multiMoisturizersSugarsSucroseGlucoseAminoAcidsAlaninePcaSalts',
    subDescription: 'affectHydrationIsDifferentLevels',
  },
  {
    id: 49,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/q-10-icon.png',
    title: 'liposomeQTen',
    description: 'energizesSkinAndAffectsBuildUpOfCollagenToReduceWrinkles',
  },
  {
    id: 50,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/allantoin.png',
    title: 'allantoin',
    description: 'helpsToHydrateSkinReduceItchinessAndImproveOverallTexture',
  },
  {
    id: 51,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
    title: 'probioticLactobacillusFerment',
    description: 'repairsBarrierBalancesAndBoostsSkinNaturalProtection',
  },
  {
    id: 52,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/rice-lipides.png',
    title: 'riceLipids',
    description: 'strongMoisturizerAndBarrierEnhancer',
  },
  {
    id: 53,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-anti-ox.png',
    title: 'naturalAntiOxGreenCaviarWakameLicoriceRootElderberryFruitGreenTea',
    subDescription: 'reducesMicroInflammationsAndReducesOxidativeDamage',
    ingredientsList: getIngredientsById([117, 118, 119, 120, 121]),
  },
  {
    id: 54,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
    title: 'niacinamide',
    description: 'brightensAndBalances',
  },
  {
    id: 55,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/multi-moisturize.png',
    title: 'multiMoisturizers',
    ingredientsList: getIngredientsById([127, 128, 129, 130, 131]),
  },
  {
    id: 56,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/antioxidants.png',
    title: 'antiOx',
    ingredientsList: getIngredientsById([122, 123, 124, 125, 126]),
  },
  {
    id: 57,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/fruit-acid-complex.png',
    title: 'fruitAcidComplexOrangeLemonSugarCaneBlueberry',
    description: 'immediatelyVisibleResultsBoostedCellularRenewalBothAffect',
    ingredientsList: getIngredientsById([132, 133, 134, 135]),
  },
  {
    id: 58,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/white-willow-bark-extract.png',
    title: 'whiteWillowBarkExtract',
    description: 'naturalSourceOfSalicylicAcidStrongExfoliatorAndBooster',
  },
  {
    id: 59,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/probiotic.png',
    title: 'lactobacillusFermentLysateFiltrate',
    description: 'probioticBoosterForCellularRespirationCollagenProduction',
  },
  {
    id: 60,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/chili-peptides.png',
    title: 'chiliPeptides',
    description: 'affectIncreasedCellularTurnoverWhichPrecursorOfYouthfulLooks',
  },
  {
    id: 61,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/cinnamon-icon.png',
    title: 'liposomeCinnamon',
    description: 'strongAntioxidantWithToneEveningAndWhiteningProperties',
  },
  {
    id: 62,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/beta-glucan.png',
    title: 'betaGlucan',
    description: 'immunoBoostSimultaneouslyCapableOfIncreasingCollagenSynthesis',
  },
  {
    id: 63,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/witch-hazel-water.png',
    title: 'witchHazelWater',
    description: 'reducesMicroInflammationsSkin',
  },
  {
    id: 64,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/olive-leaf-extract-licorice-extract-icon.png',
    title: 'naturalAntiOxOliveLeafExtractElderberryFruitExtractGotuKola',
    description: 'naturalRejuvenationSupport',
    ingredientsList: getIngredientsById([146, 147, 148]),
  },
  {
    id: 65,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/tropical-fruit-enzymes.png',
    title: 'tropicalFruitEnzymes',
    description: 'enzymesWorkSynergyMildlyExfoliateAndReduceVsibleWrinkles',
    ingredientsList: getIngredientsById([136, 137, 138, 139, 140, 141, 142, 143, 144, 145]),
  },
  {
    id: 66,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/minerals-rca-icon.png',
    title: 'sodiumRcaTrehalose',
    description: 'naturalMoisturizingFactorsIncreaseHydrationLevelsDermis',
    ingredientsList: getIngredientsById([151, 152]),
  },
  {
    id: 67,
    icon: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/niacinamide.png',
    title: 'niacinamidePanthenol',
    description: 'brightenAndRebootSkin',
    ingredientsList: getIngredientsById([149, 150]),
  },
];
