import { useRef } from 'react';
import { capitalize, isArray, isNil, isPlainObject } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import { cryptoSign } from '@oneecosystem/dealshaker-core';
import { Row, Col, Button, showError, Form, Input, inputValidation, Modal } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { modalContainer, publicContentContainer } from './styles';

const Login = ({ history, location }) => {
  const { getText } = useTranslations();
  const { state } = location;
  const privacyPolicyModalRef = useRef();
  const termsAndConditionsModalRef = useRef();

  const handleLogin = async (data) => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map((el) => el[0]));
    //     Error from sts identity                  Default behavior
    if (err) return isArray(err) ? err.forEach(showError) : showError(err);

    if (state?.navigateTo) history.push({ pathname: state.navigateTo });

    if (!err && isNil(state?.navigateTo)) history.replace('/');

    return [res, err];
  };

  return (
    <>
      <Row css={publicContentContainer}>
        <Col sm={12} md={12} lg={7} className="action-column">
          <Logo onClick={() => history.push(ROUTES.Home)} className="logo" />

          <Form onSubmit={handleLogin} className="form-container">
            <h1 className="title">{getText('logIn')}</h1>
            <p className="desc">{getText('startToExploringLiposomalWorld')}</p>
            <Input
              required
              formId="email"
              margin="0 0 1em"
              label={getText('emailAddress')}
              validate={inputValidation('required', 'email')}
            />
            <Input
              required
              type="password"
              formId="password"
              margin="0 0 1em"
              label={getText('password')}
              validate={inputValidation('required', 'password')}
            />

            <Button type="link" linkTo={ROUTES.ForgotPassword} className="forgot-button">
              {getText('forgotPassword')}
            </Button>

            <div className="action-container">
              {({ onSubmit }) => (
                <Button small onClick={onSubmit} className="login-button">
                  {getText('logIn')}
                </Button>
              )}
            </div>
          </Form>
          <div>
            <p className="paragraph">
              {`${getText('byContinuingYouAgree')} `}
              <Button type="link" className="underline-text" onClick={() => termsAndConditionsModalRef.current?.open()}>
                {getText('termsOfService')}
              </Button>{' '}
              {getText('and')}{' '}
              <Button type="link" className="underline-text" onClick={() => privacyPolicyModalRef.current?.open()}>
                {capitalize(getText('privacyPolicy'))}.
              </Button>
            </p>
            <p className="paragraph"> {getText('ifDoNotHaveAccountCreate')}</p>
          </div>
        </Col>
        <Col sm={12} md={12} lg={5} className="pictureContainer">
          <div className="picture" />
        </Col>
      </Row>
      <Modal large ref={privacyPolicyModalRef} closeIcon css={modalContainer}>
        <h3 className="title">{getText('privacyPolicy')}</h3>
        <hr className="horizontal-line" />
        <div className="scrollable-container">
          <p className="more-info">{getText('privacyPolicyDescription')}</p>
        </div>
      </Modal>

      <Modal large ref={termsAndConditionsModalRef} closeIcon css={modalContainer}>
        <h3 className="title">{getText('termsAndConditions')}</h3>
        <hr className="horizontal-line" />
        <div className="scrollable-container">
          <p className="more-info">{getText('termsAndConditionsDescription', { isCrypto: cryptoSign() })}</p>
        </div>
      </Modal>
    </>
  );
};

export default Login;
