import PropTypes from 'prop-types';
import { sub, mul } from 'exact-math';
import { isNumber, pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, getDealFinalPrices } from '@oneecosystem/dealshaker-core';
import { PriceLabel } from 'components';
import { priceContainer } from './styles';

export const ProductPrice = ({ product, showSavings, showFinalPrice, className }) => {
  const { displayFiatOnly } = useCurrencies((currenciesState) =>
    pick(currenciesState, ['displayFiatOnly', 'selectedCurrency']),
  );
  const { getText } = useTranslations();

  const hasFiatPrice = isNumber(product?.priceFiat);
  const hasDiscount = isNumber(product?.discountedPriceFiat) || isNumber(product?.discountedPriceCrypto);
  const applyQuantity = (price) =>
    isNumber(product?.quantity) && isNumber(price) && Number(product.quantity) > 1
      ? mul(Number(product.quantity), price)
      : price;
  const price = applyQuantity(product.price);
  const priceFiat = hasFiatPrice ? applyQuantity(product.priceFiat) : null;
  const priceCrypto = applyQuantity(product?.priceCrypto);
  const discountedPrice = hasDiscount && applyQuantity(product?.discountedPrice);
  const discountedPriceFiat = hasDiscount && applyQuantity(product?.discountedPriceFiat);
  const discountedPriceCrypto = applyQuantity(product?.discountedPriceCrypto);
  const finalPrice = getDealFinalPrices(product);
  const savingFiat = hasDiscount && sub(product.price, product.discountedPrice);
  const quantity = product.quantity;
  const fiatOriginCurrency = product?.currencyCode;

  const getPrintPriceConfig = (priceData) => {
    const printPriceConfig = {
      fiat: priceData.price,
      fiatOriginCurrency,
      total: priceData.price,
    };

    if (!displayFiatOnly) {
      printPriceConfig.fiat = priceData.priceFiat;
      printPriceConfig.fiatCrypto = priceData.priceCrypto;
      printPriceConfig.percentage = product?.percentRatio;
    }

    return printPriceConfig;
  };

  const priceComponent = () => {
    switch (true) {
      case showFinalPrice:
        return (
          <p className="price-styles">
            {!!Number(quantity) && <span className="quantity">{quantity} x </span>}
            <PriceLabel {...getPrintPriceConfig(finalPrice)} />
          </p>
        );
      case hasDiscount:
        return (
          <>
            <p className="before-discount-styles">
              <PriceLabel {...getPrintPriceConfig({ price, priceFiat, priceCrypto })} />
            </p>
            <p className="price-styles">
              <PriceLabel
                {...getPrintPriceConfig({
                  price: discountedPrice,
                  priceFiat: discountedPriceFiat,
                  priceCrypto: discountedPriceCrypto,
                })}
              />
            </p>
          </>
        );
      default:
        return (
          <p className="price-styles">
            <PriceLabel {...getPrintPriceConfig({ price, priceFiat, priceCrypto })} />
          </p>
        );
    }
  };

  return (
    <div css={priceContainer} {...(className && { className })}>
      {priceComponent()}

      {showSavings && hasDiscount && (
        <p className="saving-styles">
          <>
            {getText('saveApproximatelyPrice', { price: '' })}
            <PriceLabel fiat={savingFiat} total={savingFiat} />
          </>
        </p>
      )}
    </div>
  );
};

ProductPrice.propTypes = {
  product: PropTypes.object,
  showSavings: PropTypes.bool,
  showFinalPrice: PropTypes.bool,
  className: PropTypes.string,
};

export default ProductPrice;
