import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getText } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Col, Icon, Image, PriceLabel, Row, Tooltip, BecomeAffiliateCart } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { addProduct, useProductsStore, useUserStore } from 'stores';
import { productsContainer, contentInfoTooltipContainer } from './styles';

const Products = ({ isMobile }) => {
  const history = useHistory();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const productsAllData = useProductsStore();
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price?.priceFiat)}
        fiatOriginCurrency={price?.currencyCode}
        fiatDesiredCurrency={DEFAULT_CURRENCY.code}
        fiatCrypto={price?.priceCrypto}
        percentage={price?.percentRatio}
        total={price?.discountedPrice ?? price?.price}
      />
    );

  const productInformation = (product) => (
    <div className="product-info-container">
      <h3 className="product-title">{getText(product?.title)}</h3>
      <div className="price-icon-quantity-container">
        <p className="product-price">
          {printPrice({
            fiat: Number(product?.discountedPrice ?? product?.price),
            fiatOriginCurrency: product?.currencyCode,
            fiatDesiredCurrency: selectedCurrency?.code ?? DEFAULT_CURRENCY.code,
            total: product?.discountedPrice ?? product?.price,
          })}
        </p>
        <Tooltip
          content={convertedToLocalCurrencyPrice(product)}
          placement="bottom"
          noArrow
          contentClassName={contentInfoTooltipContainer}
          className="info-tooltip">
          <Icon iconName="info" size={16} className="icon" />
        </Tooltip>
        <p className="product-quantity">
          {product?.numberOfQuantity
            ? getText('quantityMl', { quantity: product.quantityMl, numberOfQuantity: product.numberOfQuantity })
            : getText('ml', { quantity: product.quantityMl })}
        </p>
      </div>
      <p className="product-description">{getText(product?.description)}</p>
      <div className="action-container">
        <Button type="default" className="button-default" onClick={() => addProduct(isAuthenticated, product)}>
          {getText('buyNow')}
        </Button>
        <Button
          type="link"
          className="button-link"
          onClick={() => history.push({ pathname: `/products/${product.itemId}`, state: product })}>
          {getText('learnMore')}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div css={productsContainer}>
        <h4 className="title">{getText('naturalLineOfLiposomalProducts')}</h4>

        {productsAllData?.length > 0 &&
          productsAllData?.map((el, ind) => (
            <Row key={ind}>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="column">
                {el?.isRightImage && !isMobile ? (
                  productInformation(el)
                ) : (
                  <Image src={el.listingImage} alt="product-image" className="product-image" />
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} key={ind} className="column">
                {el?.isRightImage && !isMobile ? (
                  <Image src={el.listingImage} alt="product-image" className="product-image" />
                ) : (
                  productInformation(el)
                )}
              </Col>
            </Row>
          ))}
      </div>
      <BecomeAffiliateCart />
    </>
  );
};

export default Products;
