import { keyframes } from '@emotion/react';
import { theme } from 'styles';
import picturesIcon from 'assets/images/silver-crystal.svg';

export const publicContentContainer = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',

  '.action-column': {
    padding: '0 128px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '.logo': {
      height: 40,
      width: 'auto',
      position: 'absolute',
      top: 20,
      left: 20,
      cursor: 'pointer',
    },

    '.form-container': {
      maxWidth: 400,

      '.title': {
        fontWeight: 500,
      },

      '.desc': {
        fontSize: 18,
        marginBottom: 24,
      },

      '.forgot-button': {
        padding: 0,
        color: theme.blue,
        marginBottom: 16,
      },

      '.action-container': {
        display: 'flex',
        marginBottom: 24,

        '.login-button': {
          width: '100%',
          textTransform: 'uppercase',
        },
      },
    },

    '.paragraph': {
      maxWidth: 400,
      color: theme.gray400,
      lineHeight: 1.4,
      marginBottom: 12,
      fontSize: 14,

      '.underline-text': {
        textDecoration: 'underline',
        padding: 0,
      },
    },

    '@media (max-width: 992px)': {
      padding: '0 32px',
      zIndex: 10,
    },
  },

  '.pictureContainer': {
    backgroundColor: theme.blueDark,
    position: 'relative',

    '.picture': {
      backgroundImage: `url(${picturesIcon})`,
      backgroundPosition: '0% 40%',
      position: 'absolute',
      top: 0,
      left: -40,
      width: 'calc(100% + 40px)',
      height: '100%',
    },

    '@media (max-width: 992px)': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
    },
  },
};

export const forgotPasswordContainer = {
  width: '100vw',
  height: '100vh',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 32px',

  '.logo': {
    height: 40,
    width: 'auto',
    position: 'absolute',
    top: 20,
    left: 20,
    cursor: 'pointer',
  },

  '.form-container': {
    maxWidth: 500,
    border: `1px solid ${theme.black40}`,
    borderRadius: 10,
    boxShadow: theme.boxShadowDark,
    padding: 32,

    '.lock-icon-container': {
      backgroundColor: theme.secondary,
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      borderRadius: 10,
      marginBottom: 12,
    },

    '.title': {
      fontWeight: 400,
      marginBottom: 12,
    },

    '.desc': {
      fontSize: 16,
      marginBottom: 24,
    },

    '.action-container': {
      display: 'flex',
      marginBottom: 24,
    },
  },
};

export const actionContainer = (isValid) => ({
  display: 'flex',
  marginBottom: 24,

  '.login-button': {
    width: '100%',
    borderRadius: 8,
    padding: 16,
    fontSize: 16,
    textTransform: 'uppercase',
    cursor: !isValid ? 'not-allowed' : 'pointer',
    minHeight: '4.1rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    color: theme.white,
    lineHeight: 1,
    border: '1px solid transparent',
    opacity: 1,
    backgroundColor: isValid ? theme.black : theme.black56,
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
});

const lock = keyframes`
  50% {top:-4%;}
	100% {top:-6%;}
`;

const colors = keyframes`
  50% {transform:scale(1.1);}
  100% {color:${theme.blueDarkLight}}
`;

export const mainContainer = {
  width: '100%',
  height: 'calc(100vh - 40px)',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  'div[role=button]': {
    color: 'rgb(162,163,166)',
  },

  h1: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '30vw',
    display: 'block',
    margin: '0',
    color: '#9ae1e2',
    position: 'relative',
    zIndex: '0',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '1.7s',

    '&:before': {
      content: '"U"',
      position: 'absolute',
      top: '-9%',
      right: '40%',
      transform: 'rotate(180deg)',
      fontSize: '15vw',
      color: theme.primary,
      zIndex: '-1',
      textAlign: 'center',
      animation: `${lock} .2s ease-in-out forwards`,
      animationDelay: '1.5s',
    },
  },

  h2: {
    color: '#9ae1e2',
    fontSize: '5vw',
    margin: '0',
    textTransform: 'uppercase',
    textAlign: 'center',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '2s',
  },
};

export const modalContainer = {
  padding: 20,
  display: 'flex',
  flexDirection: 'row',

  '.title': {
    color: theme.black,
  },

  '.scrollable-container': {
    height: 500,
    overflow: 'auto',

    '.more-info': {
      fontSize: 16,
      fontWeight: 300,
      color: theme.gray300,
      lineHeight: 1.4,
    },
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
  },
};
