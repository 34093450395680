import { theme } from 'styles';

export const emptyShoppingCartContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.white,
  fontWeight: 600,
  minWidth: '15rem',

  '.empty-cart-image': {
    width: '12rem',
    height: '12rem',
    margin: '4em 0 3em',
  },

  '.title': {
    fontSize: '1.4rem',
    marginBottom: '2em',
    color: theme.gray500,
  },

  '.try-to-login-button': {
    marginBottom: '1em',
    textTransform: 'uppercase',
  },

  '@media (max-width: 576px)': {
    width: '100%',
  },
};
