import { getText } from '@veraio/strank';
import { deepEqual, isArray, isFunction, setStorageValue, getStorageValue } from '@veraio/core';
import { calculateShoppingCart } from '@oneecosystem/dealshaker-core';
import { createStore, useStore, showApiError, showSuccess, showInfo } from 'components';
import {
  getShoppingCart,
  addToShoppingCart,
  updateShoppingCartProductQuantity,
  updateShoppingCartDelivery,
  updateShoppingCartPaymentMethods,
  removeProductFromShoppingCart,
  removeAllProductsFromShoppingCart,
} from 'services';

const storageKey = 'shopping-cart';

const initState = {
  cashPaymentMethodId: null,
  cryptoPaymentMethodId: null,
  businesses: [],
};

const shoppingCartStore = createStore(initState);

export const initShoppingCartStore = (state) => {
  shoppingCartStore.setState((prev) => ({ ...prev, ...(state ?? {}) }));
};

const setShoppingCart = (data) => {
  const oldState = shoppingCartStore.getState();
  const newState = calculateShoppingCart(isFunction(data) ? data(oldState) : data);
  !deepEqual(newState, oldState) && shoppingCartStore.setState(newState);
};

const emptyShoppingCart = () => setShoppingCart(initState);

export const fetchShoppingCart = async () => {
  const storage = getStorageValue(storageKey);
  const { businesses } = storage ?? {};

  // If the user is not logged in and have items to cart, after login we add those items to his cart and empty the local storage
  if (businesses?.length && businesses.every((business) => business.items.length)) {
    const [addToCartRes, err] = await addToShoppingCart(businesses.flatMap((el) => el.items));
    if (err) {
      showApiError(err);

      const [shoppingCartRes, shoppingCartErr] = await getShoppingCart();
      shoppingCartErr ? showApiError(shoppingCartErr) : setShoppingCart(shoppingCartRes);
    } else setShoppingCart(addToCartRes);

    setStorageValue(storageKey, initState);
    return;
  }

  // User is authenticated and there is no items into local storage which should be transferred
  // Fetch the cart from API and fill the state with response data
  const [res, err] = await getShoppingCart();
  err ? showApiError(err) : setShoppingCart(res);
};

export const loadFromLocalStorage = () => {
  if (!getStorageValue(storageKey)?.businesses?.every((business) => business?.items?.length))
    setStorageValue(storageKey, initState);

  setShoppingCart(getStorageValue(storageKey));
};

export const addProduct = async (isAuthenticated, item) => {
  if (isAuthenticated) {
    // Add request require an array of id's, if user is logged pass hole product object on normal flow
    // When you have items as not logged in user, after login you have to insert all items from anonymous cart to profile cart
    // This is a lot easier when you have request items as array
    const [res, err] = await addToShoppingCart(isArray(item) ? item : [item]);
    if (err) return showApiError(err);
    setShoppingCart(res);
  } else {
    const itemToAdd = {
      ...item,
      media: item?.media?.at(0),
      quantity: 1,
      selectedBusinessAddressId: null,
      selectedUserAddressId: null,
    };

    const businessToAdd = {
      businessId: item.businessId,
      businessName: item.businessName,
      items: [itemToAdd],
      businessAddresses: 'item.addresses',
      promoCode: null,
    };

    const oldState = shoppingCartStore.getState();

    const newState = {
      ...oldState,
      businesses: oldState.businesses.find((el) => el.businessId === item.businessId)
        ? oldState.businesses.map((business) =>
            business.businessId === item.businessId
              ? {
                  ...business,
                  items: business.items.find((el) => el.id === item.id)
                    ? business.items.map((el) => (el.id === item.id ? { ...el, quantity: el.quantity + 1 } : el))
                    : business.items.concat(itemToAdd),
                }
              : business,
          )
        : oldState.businesses.concat(businessToAdd),
    };

    setShoppingCart(newState);
    setStorageValue(storageKey, newState);
  }

  showSuccess(getText('productSuccessfullyAdded'));
};

export const updateProductQuantity = async (isAuthenticated, item) => {
  if (isAuthenticated) {
    const [res, err] = await updateShoppingCartProductQuantity(item);
    if (err) return showApiError(err);
    setShoppingCart(res);
  } else {
    const oldState = shoppingCartStore.getState();
    const newState = {
      ...oldState,
      businesses: oldState.businesses.map((business) => ({
        ...business,
        items: business.items.map((product) =>
          product.id === item.id ? { ...product, quantity: item.quantity } : product,
        ),
      })),
    };
    setStorageValue(storageKey, newState);
    setShoppingCart(newState);
  }
};

export const updateDeliveryAddress = async (newBusinesses) => {
  const [res, err] = await updateShoppingCartDelivery(newBusinesses.flatMap((el) => el.items));
  !err && setShoppingCart(res);
  return [res, err];
};

export const resetDeliveryAddress = () =>
  setShoppingCart((prev) => ({
    ...prev,
    businesses: prev.businesses.map((business) => ({
      ...business,
      items: business.items.map((item) => ({
        ...item,
        selectedBusinessAddressId: null,
        selectedUserAddressId: null,
      })),
    })),
  }));

export const updatePaymentMethods = async (payments) => {
  const oldState = shoppingCartStore.getState();
  const [res, err] = await updateShoppingCartPaymentMethods({
    cashPaymentMethod: oldState.cashPaymentMethodId,
    cryptoPaymentMethod: oldState.cryptoPaymentMethodId,
    ...payments,
  });
  err ? showApiError(err) : setShoppingCart(res);
};

export const removeProduct = async (isAuthenticated, id) => {
  if (isAuthenticated) {
    const [res, err] = await removeProductFromShoppingCart(id);
    if (err) return showApiError(err);

    setShoppingCart(res);
    showInfo(getText('productSuccessfullyRemoved'));
    return res;
  }

  const oldState = shoppingCartStore.getState();
  const newState = {
    ...oldState,
    businesses: oldState.businesses
      .map((business) => ({
        ...business,
        items: business.items.filter((product) => product.id !== id),
      }))
      .filter((business) => business.items.length),
  };
  setStorageValue(storageKey, newState);
  setShoppingCart(newState.businesses.length ? newState : initState);

  showInfo(getText('productSuccessfullyRemoved'));
};

export const removeAllProducts = async (isAuthenticated) => {
  if (isAuthenticated) {
    const [, err] = await removeAllProductsFromShoppingCart();
    if (err) return showApiError(err);
  } else setStorageValue(storageKey, initState);

  emptyShoppingCart();

  showInfo(getText('allProductsSuccessfullyRemoved'));
};

export const useShoppingCart = (callback) => useStore(shoppingCartStore, callback);
