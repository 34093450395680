import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { isNil, setStorageValue } from '@veraio/core';
import { Button, Icon, Spinner, EmptyShoppingCart, MiniShoppingCartItem } from 'components';
import { ROUTES } from 'enums';
import { useShoppingCart, useUserStore } from 'stores';
import { badgeNumber, shoppingCartPopupContainer } from './styles';

const MiniShoppingCart = () => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore();
  const shoppingCart = useShoppingCart();
  const history = useHistory();
  const [showContainer, setShowContainer] = useState(false);
  const cartItems = shoppingCart?.businesses?.flatMap((business) => business.items);

  const handleLoginClick = () => {
    history.push({ pathname: ROUTES.Login, state: { navigateTo: ROUTES.ShoppingCart } });
    cartItems.length && setStorageValue('redirectToShoppingCart', true);
  };

  return (
    <div css={shoppingCartPopupContainer(showContainer)}>
      <div
        className="shopping-cart-badge-container"
        role="button"
        tabIndex={0}
        onClick={() => setShowContainer((prev) => !prev)}>
        <Icon iconName="shopping_cart" className="icon-nav-actions" />
        {cartItems?.length >= 1 && <span css={badgeNumber(cartItems?.length < 1)}>{cartItems?.length}</span>}
      </div>

      {showContainer && (
        <div className="opened-content-container" onMouseLeave={() => setShowContainer(false)}>
          <div className="product-container">
            {!cartItems ? (
              <div className="spinner-container">
                <Spinner size={70} loading={isNil(cartItems)} />
              </div>
            ) : cartItems.length ? (
              <div className="mini-cart-items-container">
                <h4 className="header-title"> {getText('shoppingCart')}</h4>

                <div className="cart-items-container">
                  {cartItems?.map((product) => (
                    <MiniShoppingCartItem key={product.dealId ?? product.id} product={product} />
                  ))}
                </div>
                {isAuthenticated ? (
                  <Button
                    type="primary"
                    className="proceed-button"
                    linkTo={ROUTES.ShoppingCart}
                    onClick={() => showContainer(false)}>
                    {getText('proceedToCheckout')}
                  </Button>
                ) : (
                  <Button type="primary" className="proceed-button" onClick={handleLoginClick}>
                    {getText('proceedToCheckoutAndLogIn')}
                  </Button>
                )}
              </div>
            ) : (
              <EmptyShoppingCart onClickLogin={handleLoginClick} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniShoppingCart;
