export const ROUTES = {
  // private routes
  MyAffiliates: '/my-affiliates',
  ShoppingCart: '/shopping-cart',

  // public routes
  AuthorizationCallback: '/authorization-callback',
  // REMOVE TAG HIDE FOR FIRST VERSION
  // BecomeAnAffiliate: '/become-an-affiliate',
  // BecomeAnAffiliateApply: '/become-an-affiliate/apply',
  BrandStory: '/brand-story',
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Delivery: '/delivery',
  ForgotPassword: '/forgot-password',
  Home: '/home',
  Ingredients: '/ingredients',
  Logout: '/logout',
  Login: '/login',
  Products: '/products',
  ProductDetails: '/products/:productId',
  SkinCare: '/skin-care',
};
