export const SAMPLE_INGREDIENTS = [
  { id: 1, title: 'biotin', description: 'vitaminB7HelpsMaintainHealthySkin' },
  { id: 2, title: 'panthenol', description: 'formOfVitaminB5MoisturizesSkin' },
  { id: 3, title: 'thiamineHci', description: 'vitaminB1HelpsImproveSkinTexture' },
  { id: 4, title: 'cyanocobalamin', description: 'vitaminB12HelpsCellRegenerationAndImproveSkinTone' },
  { id: 5, title: 'riboflavin', description: 'vitaminB2PromotesHealthySkinByHelping' },
  { id: 6, title: 'pyridoxine', description: 'vitaminB6HelpsRegulateSebumProduction' },
  { id: 7, title: 'folicAcid', description: 'vitaminB9PromotesCellRenewalHelpingSkinLookFresh' },
  { id: 8, title: 'menadione', description: 'syntheticFormOfVitaminKSupportsSkinHealing' },
  { id: 9, title: 'bioflavonoids', description: 'theseAreAntioxidantsThatProtectSkin' },
  { id: 10, title: 'ascorbicAcid', description: 'vitaminCBrightensSkin' },
  { id: 11, title: 'tocopherylAcetate', description: 'stableFormOfVitaminEProtectsSkin' },
  { id: 12, title: 'glutathione', description: 'powerfulAntioxidantHelpsBrightenSkin' },
  { id: 13, title: 'sodiumPca', description: 'naturalMoisturizingIngredientHelpsKeepSkinHydrated' },
  { id: 14, title: 'magnesiumPca', description: 'helpsToHydrateAndStrengthenSkin' },
  { id: 15, title: 'zincPca', description: 'controlsExcessOilReducesAcne' },
  { id: 16, title: 'manganesePca', description: 'providesAntioxidantProtectionAndHelpsCollagenProduction' },
  {
    id: 17,
    title: 'averrhoaCarambolaStarfruitFruitExtract',
    description: 'richVitaminCAntioxidantsExtractHelps',
  },
  {
    id: 18,
    title: 'passifloraIncarnataPassionfruitFruitExtract',
    description: 'packedWithVitaminsAAndCExtractNourishesSkin',
  },
  {
    id: 19,
    title: 'actinidiaChinensisKiwiFruitExtract',
    description: 'highVitaminsCAndEKiwiExtractProvidesAntioxidantBenefits',
  },
  {
    id: 20,
    title: 'garciniaMangostanaMangosteenPeelExtract',
    description: 'knownForPowerfulAntioxidantPropertiesMangosteenExtract',
  },
  {
    id: 21,
    title: 'ananasSativusPineappleFruitExtract',
    description: 'containsBromelainEnzymeGentlyExfoliatesSkin',
  },
  { id: 22, title: 'punicaGranatumPomegranateExtract', description: 'richVitaminCAntioxidantsExtractHelps' },
  {
    id: 23,
    title: 'litchiChinensisLycheeFruitExtract',
    description: 'packedWithVitaminCAndAntioxidantsLycheeExtractHelps',
  },
  {
    id: 24,
    title: 'zizyphusJujubaJujubeFruitExtract',
    description: 'knownSoothingPropertiesJujubeExtractHelps',
  },
  {
    id: 25,
    title: 'psidiumGuajavaGuavaFruitExtract',
    description: 'richVitaminsAndAntioxidantsGuavaExtractHelps',
  },
  {
    id: 26,
    title: 'leuconostocRadishRootFermentFiltrate',
    description: 'naturalFermentDerivedFromRadishRootPreservative',
  },
  { id: 27, title: 'panthenol', description: 'formOfVitaminB5DeeplyHydrates' },
  { id: 28, title: 'aloeVera', description: 'soothingPlantExtractHydratesSkin' },
  { id: 29, title: 'oliveLeafExtract', description: 'richAntioxidantsHelpsProtectSkinFromDamage' },
  { id: 30, title: 'licoriceExtract', description: 'helpsBrightenSkinReduceDarkSpots' },
  {
    id: 31,
    title: 'acetylHexapeptide8',
    description: 'alsoKnownAsBotoxInBottlePeptideHelpsReduceAppearanceOfFineLines',
  },
  { id: 32, title: 'palmitoylTripeptide1', description: 'peptideThatStimulatesCollagenProduction' },
  { id: 33, title: 'palmitoylTetrapeptide7', description: 'reducesInflammationAndPromotesSkinHealing' },
  { id: 34, title: 'palmitoylPentapeptide4', description: 'boostsCollagenAndElastinProduction' },
  { id: 35, title: 'tripeptide29', description: 'stimulatesCollagenSynthesis' },
  { id: 36, title: 'acaciaGum', description: 'naturalThickenerAndEmulsifierHelpsStabilizeFormulations' },
  { id: 37, title: 'rhizobianGum', description: 'naturalPolysaccharideProvidesExcellentMoisturizingProperties' },
  { id: 38, title: 'pvpPolyvinylpyrrolidone', description: 'syntheticPolymerOftenUsedFilmFormingAgent' },
  { id: 39, title: 'kelp', description: 'richVitaminsAndMineralsKelpDeeplyHydratesSkin' },
  { id: 40, title: 'spirulina', description: 'blueGreenAlgaePackedWithAntioxidantsAndNutrients' },
  { id: 41, title: 'ascorbicAcid', description: 'purestFormOfVitaminCBrightensSkinBoostsCollagenProduction' },
  {
    id: 42,
    title: 'rosehipCo2Extract',
    description: 'richAntioxidantsAndEssentialFattyAcidsExtractHelpsImproveSkinTexture',
  },
  {
    id: 43,
    title: 'sodiumAscorbylSulfate',
    description: 'stableFormOfVitaminCHelpsBrightenSkinReduceDarkSpots',
  },
  {
    id: 44,
    title: 'microencapsulatedVitaminC',
    description: 'encapsulatedVitaminCReleasesGraduallyProvidingLongLastingBrightness',
  },
  {
    id: 45,
    title: 'acetylHexapeptide8Argireline',
    description: 'oftenReferredToBotoxInBottlePeptideHelpsRelaxFacialMuscles',
  },
  {
    id: 46,
    title: 'palmitoylTripeptide1',
    description: 'stimulatesCollagenProductionAndHelpsImproveSkinFirmness',
  },
  { id: 47, title: 'palmitoylTetrapeptide7', description: 'soothesInflammationAndPromotesSkinHealing' },
  { id: 48, title: 'palmitoylPentapeptide4', description: 'boostsCollagenAndElastinSynthesis' },
  { id: 49, title: 'dipeptide2', description: 'reducesPuffinessAndDarkCirclesUnderEyes' },
  {
    id: 50,
    title: 'palmitoylTetrapeptide3',
    description: 'enhancesSkinElasticityAndReducesAppearanceOfFineLines',
  },
  { id: 51, title: 'carnosine', description: 'antioxidantPeptideProtectsAgainstOxidativeStress' },
  { id: 52, title: 'tripeptide29', description: 'supportsCollagenSynthesisLeadingToSmoother' },
  { id: 53, title: 'curcumaCo2TurmericExtract', description: 'richInCurcuminHasPotentAntiInflammatory' },
  { id: 54, title: 'licorice', description: 'knownForSoothingPropertiesHelpsBrightenSkin' },
  { id: 55, title: 'greenTea', description: 'packedWithAntioxidantsProtectsSkinFromEnvironmentalDamage' },
  {
    id: 56,
    title: 'abyssinianOil',
    description: 'derivedFromAbyssinianPlantLightweightOilRichOmega9FattyAcids',
  },
  { id: 57, title: 'redGrapeSeedOil', description: 'highAntioxidantsAndLinoleicAcidHelpsProtectSkin' },
  { id: 58, title: 'squalane', description: 'highlyEffectiveMoisturizerMimicsSkinNaturalOils' },
  { id: 59, title: 'apricotOil', description: 'richVitaminsAAndENourishesAndHydratesSkin' },
  { id: 60, title: 'biotinVitaminB7', description: 'supportsHealthySkinByPromotingHydration' },
  { id: 61, title: 'panthenolVitaminB5', description: 'moisturizesSoothesAndHealsIrritatedOrDamagedSkin' },
  { id: 62, title: 'thiamineHciVitaminB1', description: 'improvesSkinTextureAndHelpsProtectSkinFromStress' },
  {
    id: 63,
    title: 'cyanocobalaminVitaminB12',
    description: 'aidsCellRegenerationAndImproveSkinToneAndBrightness',
  },
  { id: 64, title: 'riboflavinVitaminB2', description: 'maintainsMoistureLevelsSkinAndHelpsPreventDryness' },
  {
    id: 65,
    title: 'pyridoxineVitaminB6',
    description: 'helpsRegulateOilProductionAndBeneficialForAcneProneSkin',
  },
  { id: 66, title: 'folicAcidVitaminB9', description: 'promotesCellRenewalGivingSkinFresh' },
  { id: 67, title: 'menadioneVitaminK', description: 'supportsSkinHealingAndReduceAppearanceOfDarkCircles' },
  { id: 68, title: 'bioflavonoids', description: 'antioxidantsProtectSkinFromEnvironmentalDamage' },
  { id: 69, title: 'ascorbicAcidVitaminC', description: 'brightensSkinBoostsCollagenProduction' },
  {
    id: 70,
    title: 'tocopherylAcetateVitaminE',
    description: 'stableFormVitaminEMoisturizesAndProtectsSkinFromDamage',
  },
  { id: 71, title: 'glutathione', description: 'powerfulAntioxidantBrightensSkinAndReducesDarkSpots' },
  { id: 72, title: 'fructose', description: 'naturalSugarHydratesSkinAndHelpsMaintainMoistureLevels' },
  { id: 73, title: 'glucose', description: 'anotherNaturalSugarActsHumectant' },
  { id: 74, title: 'sucrose', description: 'commonlyKnownSugarHelpsToExfoliateSkinGently' },
  { id: 75, title: 'urea', description: 'powerfulHumectantHelpsToRetainMoistureSkin' },
  { id: 76, title: 'alanine', description: 'aminoAcidHelpsToHydrateSkinAndMaintain' },
  { id: 77, title: 'asparticAcid', description: 'anotherAminoAcidSupportsSkinHydration' },
  {
    id: 78,
    title: 'hexylNicotinate',
    description: 'formOfNiacinPromotesCirculationAndProvidesWarmingSensation',
  },
  { id: 79, title: 'glutamine', description: 'helpsMaintainSkinHydrationAndSupportsSkinBarrier' },
  { id: 80, title: 'leucine', description: 'essentialForProteinSynthesisAidsInRepairingAndRejuvenatingSkin' },
  { id: 81, title: 'phenylalanine', description: 'supportsSkinHealingAndHelpRegulateMelaninProduction' },
  { id: 82, title: 'arginine', description: 'promotesBloodFlowAndImprovesSkinElasticity' },
  { id: 83, title: 'lysineHci', description: 'essentialForCollagenProductionHelpsStrengthenSkin' },
  { id: 84, title: 'proline', description: 'supportsCollagenFormationEnhancingSkinStructure' },
  { id: 85, title: 'asparticAcid', description: 'helpsImproveSkinHydrationAndElasticity' },
  { id: 86, title: 'valine', description: 'essentialAminoAcidThatAidsInSkinRepair' },
  { id: 87, title: 'glycine', description: 'supportsCollagenSynthesisAndHelpsMaintainSkinHydration' },
  { id: 88, title: 'isoleucine', description: 'playsRoleSkinRepairAndHelpsToMaintainOverallSkinHealth' },
  { id: 89, title: 'threonine', description: 'supportsCollagenProductionAndHydrationEnhancingSkinTexture' },
  { id: 90, title: 'histidine', description: 'antioxidantHelpsProtectSkinFromDamage' },
  { id: 91, title: 'serine', description: 'contributesToSkinHydrationAndHelpsMaintainSkinBarrier' },
  { id: 92, title: 'alanine', description: 'aidsMoistureRetentionAndHelpsImproveSkinTextureAndSoftness' },
  { id: 93, title: 'methionine', description: 'antioxidantSupportsSkinHealthAndHelpsProtect' },
  { id: 94, title: 'tryptophan', description: 'supportsSkinRepairAndImproveOverallSkinTone' },
  { id: 95, title: 'tyrosine', description: 'helpsRegulateMelaninProduction' },
  {
    id: 96,
    title: 'cysteine',
    description: 'antioxidantSupportsCollagenProductionAndHelpsMaintainSkinElasticity',
  },
  { id: 97, title: 'taurine', description: 'helpsProtectSkinFromEnvironmentalStress' },
  { id: 98, title: 'carnitine', description: 'aidsCellularMetabolismPromotingSkinRejuvenation' },
  { id: 99, title: 'linoleicAcidOmega6', description: 'essentialFattyAcidHelpsMaintainSkinBarrierFunction' },
  {
    id: 100,
    title: 'linolenicAcidOmega3',
    description: 'anotherEssentialFattyAcidSupportsSkinHealthByReducingInflammation',
  },
  { id: 101, title: 'liposomeRetinol', description: 'thisStableFormOfRetinolVitaminAEncapsulatedInLiposomes' },
  {
    id: 102,
    title: 'microencapsulatedRetinol',
    description: 'thisFormInvolvesEncapsulatingRetinolInMicroscopicParticlesProtect',
  },
  { id: 103, title: 'ceramide1', description: 'helpsStrengthenSkinBarrierRetainMoisture' },
  { id: 104, title: 'ceramide2', description: 'promotesHydrationAndSupportsSkinElasticity' },
  { id: 105, title: 'ceramide3', description: 'aidsSkinBarrierRepairAndMoistureRetention' },
  { id: 106, title: 'ceramide4', description: 'enhancesSkinBarrierFunctionAndHelpsMaintainMoistureLevels' },
  { id: 107, title: 'ceramide6', description: 'supportsSkinNaturalBarrierImprovingHydration' },
  { id: 108, title: 'cholesterol', description: 'worksSynergyWithCeramidesToRestoreSkinBarrier' },
  { id: 109, title: 'walnutOil', description: 'richOmega3FattyAcidsAndAntioxidantsWalnutOilNourishesSkin' },
  { id: 110, title: 'apricotOil', description: 'highVitaminsAAndELightweightOilHydrates' },
  { id: 111, title: 'redGrapeSeedOil', description: 'packedWithAntioxidantsAndLinoleicAcid' },
  { id: 112, title: 'bakuchiol', description: 'plantBasedAlternativeToRetinol' },
  { id: 113, title: 'sachaInchiOil', description: 'richOmegaFattyAcidsAntioxidantsAndVitaminsAAndE' },
  { id: 114, title: 'squalane', description: 'highlyEffectiveMoisturizerMimicsSkinNaturalOilsSqualaneHydrates' },
  { id: 115, title: 'oarweedLaminariaDigitata', description: 'typeOfBrownSeaweedRichVitaminsAndMinerals' },
  {
    id: 116,
    title: 'singleCellGreenAlgaeChlorella',
    description: 'packedWithNutrientsAndAntioxidantsAlgaeHelpsDetoxifySkin',
  },
  { id: 117, title: 'greenCaviarSeaGrapes', description: 'packedWithVitaminsAndMineralsGreenCaviar' },
  {
    id: 118,
    title: 'wakameUndariaPinnatifida',
    description: 'typeOfSeaweedContainsAntioxidantsVitaminsAndMinerals',
  },
  { id: 119, title: 'licoriceRoot', description: 'knownForSoothingAndBrighteningPropertiesLicoriceRoot' },
  {
    id: 120,
    title: 'elderberryFruit',
    description: 'richVitaminsAAndCAndAntioxidantsElderberryHelpsFightOxidativeStress',
  },
  {
    id: 121,
    title: 'greenTea',
    description: 'packedWithPowerfulAntioxidantsCalledCatechinsGreenTeaHelpsProtectSkin',
  },
  { id: 122, title: 'cordycepsMushroom', description: 'knownForAntioxidantPropertiesHelpsProtectSkin' },
  {
    id: 123,
    title: 'inonotusObliquusChagaMushroom',
    description: 'richAntioxidantsMushroomHelpsReduceInflammation',
  },
  {
    id: 124,
    title: 'ganodermaReishiMushroom',
    description: 'knownForSoothingAndAntiAgingPropertiesHelpsCombatFreeRadicals',
  },
  { id: 125, title: 'asparticAcid', description: 'aminoAcidSupportsSkinHydrationAndHelpImproveSkinTexture' },
  { id: 126, title: 'panthenolVitaminB5', description: 'moisturizingAgentSoothesAndHydratesSkin' },
  { id: 127, title: 'sucrose', description: 'naturalSugarActsHumectant' },
  { id: 128, title: 'fructose', description: 'anotherNaturalSugarRetainsMoistureSkin' },
  { id: 129, title: 'glucose', description: 'naturalHumectantHelpsDrawMoistureSkin' },
  { id: 130, title: 'alanine', description: 'aminoAcidAidsMaintainingSkinMoistureLevels' },
  { id: 131, title: 'sodiumPca', description: 'naturallyOccurringComponentSkinHelpsAttractAndBindMoisture' },
  { id: 132, title: 'orangeExtract', description: 'richCitricAcidHelpsExfoliateSkin' },
  { id: 133, title: 'lemonExtract', description: 'containsAlphaHydroxyAcidsAHAsExfoliateDeadSkinCells' },
  { id: 134, title: 'sugarCaneExtract', description: 'naturalSourceOfGlycolicAcidGentlyExfoliatesSkin' },
  {
    id: 135,
    title: 'blueberryExtract',
    description: 'packedWithAntioxidantsHelpsProtectSkinFromEnvironmentalDamage',
  },
  {
    id: 136,
    title: 'averrhoaCarambolaStarfruitFruitExtract',
    description: 'richVitaminCAntioxidantsExtractHelps',
  },
  {
    id: 137,
    title: 'passifloraIncarnataPassionfruitFruitExtract',
    description: 'packedWithVitaminsAAndCExtractNourishesSkin',
  },
  {
    id: 138,
    title: 'actinidiaChinensisKiwiFruitExtract',
    description: 'highVitaminsCAndEKiwiExtractProvidesAntioxidantBenefits',
  },
  {
    id: 139,
    title: 'garciniaMangostanaMangosteenPeelExtract',
    description: 'knownForPowerfulAntioxidantPropertiesMangosteenExtract',
  },
  {
    id: 140,
    title: 'ananasSativusPineappleFruitExtract',
    description: 'containsBromelainEnzymeGentlyExfoliatesSkin',
  },
  {
    id: 141,
    title: 'punicaGranatumPomegranateExtract',
    description: 'richAntioxidantsPomegranateExtractHelpsProtectSkinFromDamage',
  },
  {
    id: 142,
    title: 'litchiChinensisLycheeFruitExtract',
    description: 'packedWithVitaminCAndAntioxidantsLycheeExtractHelps',
  },
  {
    id: 143,
    title: 'zizyphusJujubaJujubeFruitExtract',
    description: 'knownSoothingPropertiesJujubeExtractHelps',
  },
  {
    id: 144,
    title: 'psidiumGuajavaGuavaFruitExtract',
    description: 'richVitaminsAndAntioxidantsGuavaExtractHelps',
  },
  {
    id: 145,
    title: 'leuconostocRadishRootFermentFiltrate',
    description: 'naturalFermentDerivedFromRadishRootPreservative',
  },
  { id: 146, title: 'oliveLeafExtract', description: 'richAntioxidantsOleuropeinExtractHelpsProtectSkin' },
  {
    id: 147,
    title: 'elderberryFruitExtract',
    description: 'packedWithVitaminsAAndCAndAntioxidantsElderberryHelpsFightOxidativeStress',
  },
  {
    id: 148,
    title: 'gotuKolaCentellaAsiatica',
    description: 'knownForHealingPropertiesHerbHelpsImproveCirculation',
  },
  {
    id: 149,
    title: 'niacinamideVitaminB3',
    description: 'knownForVersatileBenefitsNiacinamideHelpsImproveSkinTexture',
  },
  { id: 150, title: 'panthenolVitaminB5', description: 'deeplyHydratingIngredientSoothesAndSoftensSkin' },
  { id: 151, title: 'sodiumPca', description: 'naturalMoisturizingIngredientHelpsKeepSkinHydrated' },
  { id: 152, title: 'trehalose', description: 'naturalSugarActsPowerfulHumectant' },
];
